import "core-js/modules/es6.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'ProfileRewardsModal',
  data: function data() {
    return {
      rewardMapData: {
        profile: {
          title: this.$t('ProfileRewardsModal.labelCompleteProfile'),
          order: 1
        },
        phone: {
          title: this.$t('ProfileRewardsModal.labelVerifyPhone'),
          order: 2
        },
        email: {
          title: this.$t('ProfileRewardsModal.labelVerifyEmail'),
          order: 3
        }
      }
    };
  },
  computed: {
    profile: function profile() {
      return this.$store.getters['player/profile'];
    },
    profilePhone: function profilePhone() {
      return this.$store.getters['player/profilePhone'];
    },
    rewards: function rewards() {
      return this.$store.getters['rewards/rewards'];
    },
    rewardsAmount: function rewardsAmount() {
      var _this = this;

      return Object.keys(this.rewards).reduce(function (acc, key) {
        return acc + (_this.rewards[key].available && !_this.rewards[key].completed ? _this.rewards[key].coins : 0);
      }, 0);
    },
    getTime: function getTime() {
      return this.$store.getters['rewards/getTime'];
    },
    getExpTime: function getExpTime() {
      return this.$store.getters['rewards/expTime'];
    }
  },
  methods: {
    getOrder: function getOrder(name, status) {
      var order = this.rewardMapData[name].order;
      var completeRewardOrderStep = Object.keys(this.rewards).length;
      if (status) order += completeRewardOrderStep;
      return order;
    },
    close: function close() {
      this.$store.dispatch('modals/setRewardsModal', {
        isOpen: false
      });
    },
    actionHandler: function actionHandler(name, isCompleted) {
      if (isCompleted) return;

      if (name === 'email') {
        if (this.getExpTime > 0) return;
        this.$store.dispatch('modals/setEmailConfirmationModal', {
          isOpen: true,
          withRequest: true
        });
      } else if (name === 'profile') {
        this.$store.$cash.open({
          url: '/cash/account/'
        });
      } else if (name === 'phone') {
        var phone = this.profile.phone || this.profilePhone;

        if (!phone) {
          this.$store.dispatch('modals/setChangeNumber', {
            isOpen: true,
            closeOnBack: true
          });
        } else {
          this.$store.dispatch('player/setShowConfirmPhonePopup', true);
          this.$store.dispatch('modals/setConfirmation', {
            isOpen: true
          });
        }
      }
    }
  }
};